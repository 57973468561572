<template>
  <div id="cameraEditDialog" ref="cameraEditDialog">
    <a-modal v-model="visible" :title="title" :width="740" :getContainer="() => this.$refs.cameraEditDialog" :footer="null">
        <div v-loading="cloading" :element-loading-text="$t('alarm.a0')">
            <a-form-model ref="ruleForm" 
            :model="form" 
            :rules="rules"
            :label-col="labelCol" 
            :wrapper-col="wrapperCol"  >

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 1.摄像机所属 -->
                    <a-form-model-item label="摄像机所属" ref="parentId" prop="parentId">
                        <a-select v-model="form.parentId" placeholder="请选择摄像机所属">
                            <a-select-option
                                v-for="(itme, index) in cameraCollections"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <!-- 1.所属录像机 -->
                <a-col :span="12">
                    <a-form-model-item label="所属录像机" ref="recorderId" prop="recorderId">
                        <a-select v-model="form.recorderId" placeholder="请选择所属录像机">
                            <a-select-option
                                v-for="(itme, index) in recorders"
                                :key="index"
                                :value="itme.recorderId"
                                >
                                {{ itme.recorderName }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <!-- 2.摄像机类型 -->
                <!-- <a-col :span="12">
                     <a-form-model-item label="摄像机类型" ref="cameraType" prop="cameraType">
                        <a-select v-model="form.cameraType" placeholder="请选择摄像机类型" >
                            <a-select-option
                                v-for="(itme, index) in cameraTypes"
                                :key="index"
                                :value="itme.no"
                                >
                                {{ itme.text }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col> -->
            </a-row>

            

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.摄像机名称 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="name" prop="name">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机名称&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.name"
                            @blur="
                            () => {
                                $refs.name.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机名称"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <!-- 4.摄像机编号 :label="$t('energy.enedata490')"-->
                    <a-form-model-item  ref="cameraNo" prop="cameraNo">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机编号&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input-number style="width:100%;"
                            v-model="form.cameraNo"
                            @blur="
                            () => {
                                $refs.cameraNo.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机编号"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                
                <!-- 2.录像机频道号 -->
                <a-col :span="12">
                     <a-form-model-item label="录像机频道号" ref="recorderCh" prop="recorderCh">
                        <a-input 
                            v-model="form.recorderCh"
                            @blur="
                            () => {
                                $refs.recorderCh.onFieldBlur();
                            }
                            "
                            placeholder="请输入录像机频道号"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.摄像机IP :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="cameraIpAddress" prop="cameraIpAddress">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机IP&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.cameraIpAddress"
                            @blur="
                            () => {
                                $refs.cameraIpAddress.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机IP"
                        />
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 3.摄像机端口 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="cameraHttpPort" prop="cameraHttpPort">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机端口&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.cameraHttpPort"
                            @blur="
                            () => {
                                $refs.cameraHttpPort.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机端口"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col :span="12">
                    <!-- 3.摄像机账户 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="cameraUserId" prop="cameraUserId">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机账户&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.cameraUserId"
                            @blur="
                            () => {
                                $refs.cameraUserId.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机账户"
                        />
                    </a-form-model-item>
                

                </a-col>
                <a-col :span="12">
                    <!-- 3.摄像机密码 :label="$t('energy.enedata489')"-->
                    <a-form-model-item  ref="cameraUserPassword" prop="cameraUserPassword">
                        <span slot="label">
                                        <!-- {{$t('energy.enedata489')}}&nbsp; -->
                                        摄像机密码&nbsp;
                            <a-tooltip :title='disallowed'>
                            <a-icon type="question-circle-o" />
                            </a-tooltip>
                        </span>
                        <a-input 
                            v-model="form.cameraUserPassword"
                            @blur="
                            () => {
                                $refs.recorderUserPassword.onFieldBlur();
                            }
                            "
                            placeholder="请输入摄像机密码"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
                
                
            <a-form-model-item v-bind="tailFormItemLayout">
                <a-button type="primary" @click="onSubmit">
                    {{$t('energy.enedata173')}}
                </a-button>
                <a-button style="margin-left: 10px;" @click="resetForm" v-if="id==0">
                    {{$t('energy.enedata333')}}
                </a-button>
            </a-form-model-item>
                
            </a-form-model>
        </div>
    </a-modal>
  </div>
</template>
<script>
  import { saveVideoCameraInfo,getVideoCameraInfo } from "../../api/video";
import moment from 'moment';
export default {
  name: 'cameraEditDialog',
  props: {
    
    id: {
      type: Number,
      default:0,
    },
    cameraTypes: {
      type: Array,
      default:[{no:'',text:''}],
    },
    cameraCollections:{
      type: Array,
      default:[{no:'',text:''}],
    },
    recorders:{
      type: Array,
      default:[],
    }
  },
  data() {
    let normalName = (rule, value, callback) => {
        let regEn = /[`~!@#$%^&*+<>?:",;']/im;

        if(regEn.test(value)) {
            callback(new Error(this.$t('energy.enedata218')));
        } else {
            callback();
        }
    };

    let numberVail = (rule, value, callback) => {
        if(value==0) {
            callback(new Error(this.$t('energy.enedata524')));
        } else {
            callback();
        }
    };
    return {
        cloading:false,        
        visible:false,
        title: '摄像机设置',
        form:{
            recorderId: undefined,
            recorderName: "",
            cameraId:0,
            cameraIpAddress: "",
            cameraHttpPort:"",
            cameraType: "",
            cameraUserId: "",
            cameraUserPassword: "",
            name: "",
            cameraNo: "",
            recorderCh:"",
            siteId: 0,
            parentId: undefined

        },
        labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },

        tailFormItemLayout: {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 12,
                    offset: 12,
                },
            },
        },
        
        rules: {
            recorderCh: [
                { required: true, message: '请输入录像机频道号' , trigger: 'blur' }, //this.$t('energy.enedata505')
                { validator:numberVail, trigger: 'blur' },
            ],
            recorderId: [{ required: true, message: '请选择所属录像机', trigger: 'change' }],
            name: [
                { required: true, message: '请输入摄像机名称', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            cameraIpAddress: [
                { min: 1, max: 50, message: '摄像机IP/URL地址长度在1~50个字符内', trigger: 'blur' },
                // { validator:normalName, trigger: 'blur' },
            ],
            cameraUserId: [
                { min: 1, max: 50, message: '摄像机管理账号/KEY长度在1~50个字符内', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            cameraUserPassword: [
                { min: 1, max: 50, message: '摄像机管理密码/token长度在1~50个字符内', trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ],
            cameraType: [{ required: true, message: '请选择摄像机类型', trigger: 'change' }],
            parentId: [{ required: true, message: '请选择摄像机所属', trigger: 'change' }],
            
        }, 
        disallowed:this.$t('energy.setting.reportGr.stop')+'[`~!@#$%^ &amp; *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】',
        
        
    };
  },
  mounted(){
   
  },
  watch:{
    "$parent.cameraVisible":{
      handler(val){
          console.log('watch cameraVisible>>>',val)
          if(val){              
              this.visible = val;
              this.resetForm();
              if(this.id>0){
                  this.loadInfo();
              }else{
                //   this.form = {
                //         recorderId: 0,
                //         recorderName: "萤石云",
                //         recorderType: '',
                //         recorderIpAddress: "https://open.ys7.com/",
                //         recorderCh: '',
                //         recorderUserId: "5d8cc5f4d6174e2cb7cbe79d05879397",
                //         recorderUserPassword: "52940d0d154f07771ceda83710719379",
                //         siteId: 0,
                //         parentId: '',
                //         recorderVideoPort:'',
                //         recorderConnectPort:''
                //     };
                    this.form = {
                        recorderId: undefined,
                        recorderName: "",
                        cameraId:0,
                        cameraIpAddress: "",
                        cameraHttpPort:"",
                        cameraType: "",
                        cameraUserId: "",
                        cameraUserPassword: "",
                        name: "",
                        cameraNo: "",
                        recorderCh:"",
                        siteId: 0,
                        parentId: undefined
                    };
                    this.cloading = false;
              }
              console.log('watch cameraVisible form>>>',this.form)
          }
      },
      deep:true,
      immediate:true,
    },
    "visible":{
      handler(val){
          console.log('watch visible>>>',val)
          if(!val){
              this.$parent.cameraVisible =  val;
              this.resetForm();
              console.log('watch visible  this.$parent.cameraVisible>>>', this.$parent.recorderVisible);
              this.cloading = false;
          }
      },
      deep:true,
      immediate:true,
    },
    
  },
  methods: {
        handleChange(e){
            
        },
        loadInfo(){
            this.cloading = true;
            getVideoCameraInfo(this.id)
            .then((res) => {
                console.log("getVideoCameraInfo Result",res.data);
                if(res.errorCode === '00'){
                    if(res.data){
                        this.form = {
                            recorderId: res.data.recorder.recorderId,
                            recorderName: res.data.recorder.recorderName,
                            cameraId:res.data.camera.cameraId,
                            cameraIpAddress:res.data.camera.cameraIpAddress,
                            cameraHttpPort:res.data.camera.cameraHttpPort,
                            cameraType:res.data.camera.cameraType+"",
                            cameraUserId:res.data.camera.cameraUserId,
                            cameraUserPassword:res.data.camera.cameraUserPassword,
                            name:res.data.camera.name,
                            cameraNo:res.data.camera.cameraNo,
                            recorderCh:res.data.camera.recorderCh,
                            siteId: res.data.camera.siteId,
                            parentId: res.data.camera.parentId+"",
                        };
                        console.log("getVideoCameraInfo Result",this.form);
                    }
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('getVideoCameraInfo',err);
            });
        },
        onSubmit() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                     this.$confirm({
                        title: '确认要保存所设置的摄像机信息吗？',//
                        centered: true,
                        onOk: () => {
                            this.savaRecorderInfo();
                        },
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm() {
            if(this.$refs.ruleForm){
                this.$refs.ruleForm.resetFields();
            }
        },
        savaRecorderInfo(){
            
            let param =  {
                    action:1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    tenantId: 0,
                    camera:this.form,
                };
            this.cloading = true;
            console.log("savaRecorderInfo param",param);
            saveVideoCameraInfo(param)
            .then((res) => {
                console.log("savaRecorderInfo Result",res);
                console.log("savaRecorderInfo ErrorCode",res.errorCode);
                if(res.errorCode == '00' || res.errorCode == '03' ){
                   this.$message.success(res.msg);
                   this.visible = false;
                   this.$parent.search();
                } else {
                    this.$message.error(res.msg);
                }
                this.cloading = false;
            })
            .catch((err) => {
                this.cloading = false;
                console.log('savaRecorderInfo',err);
            });
        },
  },
};
</script>
<style scoped>
    #cameraEditDialog{
        height: 100%;
        width:100%;
        position: relative;
    }
    div /deep/.ant-modal-body{padding:10px;}
</style>
